body {
  background-color: rgb(239, 246, 250);
}

.pid {
  font-size: 0.7em;
}
.Select-input > input {
  border: none !important;
}

.Select-control {
  height: inherit !important;
}

svg.graph .node circle {
  fill: #999;
}

svg.graph .node text {
  font: 10px sans-serif;
}

svg.graph .node--internal circle {
  fill: #555;
}

svg.graph .node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

svg.graph .link {
  fill: none;
  stroke: #555;
  stroke-opacity: 0.4;
  stroke-width: 1.5px;
}

.horizontal-tree path {
  fill: transparent;
  stroke: rgb(64, 64, 64);
}

.horizontal-tree rect {
  fill: rgba(255, 255, 255, 1);
  stroke: #cccccc;
  stroke-width: 1;
}

.familytree {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 500px;
  overflow-y: show;
  overflow-x: auto;
}

.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.ui.button.icon.noborder {
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

div.ui.grid.main {
  margin-top: 2em;
  margin-left: 40px;
  margin-right: 40px;
}

.ui.footer.segment {
  margin: 5em 0em 0em;
  padding: 5em 0em;
}

.App-header {
  height: 36px;
  padding: 10px 30px;
  color: white;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main-search {
  width: 300px;
}

.search-button {
  margin-left: 10px !important;
}

.tree * {
  margin: 0;
  padding: 0;
}

.tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}
